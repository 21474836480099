.DefaultHandle_handle,.DefaultHandle_handle__horizontal,.DefaultHandle_handle__vertical{
    border-radius: 50%;
  }
  .DefaultHandle_handle__horizontal::after,.DefaultHandle_handle__horizontal::before{
    background-color: #efe921;
  }
  /* RHEOSTAT RANGE
// ------------------------- */
.DefaultProgressBar_progressBar {
  background-color:#d4865e
}
.rheostat-values {
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.6);
  margin: 5px 0px 10px 0px;
}
